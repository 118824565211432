require('./src/styles/global.css')
const React = require('react')
const Layout = require('./src/components/Layout').default

// Gatsby browser is the wrapper for browser rendering
exports.wrapPageElement = ({ element, props }) => {
  return (
<Layout {...props}>
      {element}
    </Layout>
  )
}

exports.shouldUpdateScroll = ({
  routerProps: { location },
  // getSavedScrollPosition,
}) => {
  const { hash } = location
  // I need this since the
  if (hash === '#identifyProductsDescription') {
    document.getElementById('identifyProductsDescription').scrollIntoView(false)
  }
  // if the new route is part of the list above, scroll to top (0, 0)

  return false
}

exports.shouldUpdateScroll = () => {
  window.scrollTo(0, 0)
  return false
}
