import React from 'react'
import SEO from './SEO'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ children }: any) => {
  return <>
    <SEO />
    <header
      role='banner'
      className='flex flex-col gap-4 py-6 px-10 bg-contrast/80 text-primary shadow-lightHeader items-center sticky transition backdrop-blur-lg z-40 top-0 w-full leading-none'
    >
      <div className='w-full flex justify-between items-center'>
        <Link className='' to='https://bejiyou.com/' >
        <StaticImage className='w-28 md:w-40' src="../images/be-ji-you-icon.png" alt="A dinosaur" />
        </Link>
        <nav className='gap-8 flex'>
          <a
            href='https://bejiyou.com/'
            className='text-black dark:text-white pr-2 font-semibold'>
            Shop →
          </a>
        </nav>
        {/* <div className='hamburger-demo-menubox px-4'>
          <input id='hamburger-demo1' type='checkbox' className='input-hidden' />
          <label htmlFor='hamburger-demo1' className='hamburger-demo-switch hamburger-demo-switch1'>
            <span className='hamburger-switch-line1-before'></span>
            <span className='hamburger-switch-line1'></span>
            <span className='hamburger-switch-line1-after'></span>
          </label>
          <div className='hamburger-demo-menuwrap'>
            <ul className='hamburger-demo-menulist text-lg'>
              <li className='mb-3'>
                <a
                  href='https://bejiyou.com/'
                  className=''>
                  Be ji-You TOP
                </a>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </header>
    <div className='overflow-hidden'>
      {children}
    </div>
  </>
}

export default Layout
