import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
interface SECProps {
  children?:  React.ReactNode
  description?: string
  title?: string
  keywords?: string
}
export default function SEO({ children, description, title, keywords }:SECProps) {

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          keywords
        }
      }
    }
  `)
  return (
    <Helmet>
      <html lang='ja' />
      <title>{title || site.siteMetadata.title}</title>
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta charSet='utf-8' />
      <meta name='description' content={description || site.siteMetadata.description} />
      <meta name='keywords' content={keywords + site.siteMetadata.keywords} />
      <meta property='og:title' content={title} key='ogtitle' />
      { title && (
        <meta property='og:site_name' content={title} key='ogsitename' />
      )}
      { description && (
        <meta property='og:description' content={description} key='ogdesc' />
      )}
      { keywords && (
        <meta property='og:keywords' content={keywords} key='ogkeywords' />
      )}
      {children}
    </Helmet>
  )
}
